import { AnyObj } from '@/interface/common';
import { MessageBox } from 'element-ui';
import i18n from '@/lang';
/**
 * 防抖函数  在一定时间间隔内限制触发函数执行
 * @param fn 需要执行的函数
 * @param wait 等待时间
 * @param immediate false先等待再执行,true先执行再等待
 * @returns 返回一个函数
 */
export function debounce(fn: any, wait: number, immediate = false) {
  // timer 定时函数返回唯一ID; startTimeStamp 计时起始时间
  // context 函数调用上下文; args 要执行的函数传递的参数
  let timer: any;
  let startTimeStamp = 0;
  let context: any;
  let args: any;
  const run = (timeout: number) => {
    timer = setTimeout(() => {
      const now = Date.now();
      const interval = now - startTimeStamp;
      if (interval < timeout) {
        startTimeStamp = now;
        run(wait - interval);
      } else {
        if (!immediate) {
          fn.apply(context, args)
        }
        clearTimeout(timer);
        timer = null;
      }
    }, timeout)
  }
  return function() {
    // @ts-ignore
    context = this;
    args = arguments;
    const now = Date.now();
    startTimeStamp = now;
    if (!timer) {
      if (immediate) {
        fn.appply(context, args)
      }
      run(wait);
    }
  }
}

/**
 * 节流函数
 */

export function getOffsetTop(elem: any) {
  return elem.offsetTop;
}

export function getOffsetLeft(elem: any) {
  return elem.offsetLeft;
}

// 文本内容换行
export const wrap = (text: any): any => {
  if (text && typeof text === 'string') {
    return text.replace(/\n/g, '<br>');
  } else {
    return text;
  }
}

// 是否有值
export const hasVal = (value: any): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  if (typeof value === 'string' && !value.trim()) {
    return false;
  }

  if (typeof value === 'object') {
    const check = Object.prototype.toString;
    if (check.call(value) === '[object Array]' && !value.length) {
      return false;
    }
    if (check.call(value) === '[object Object]' && !Object.keys(value).length) {
      return false;
    }
  }

  if (value !== 0 && !value) {
    return false;
  }
  return true;
}

/**
 * deepClone 深克隆
 * @param {AnyObj | any[]} source
 * @param {string[]} dDeep 不进行深克隆的属性
 * @returns {Object}
 */
export const deepClone = (source: AnyObj, dDeep: string[] = []) => {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments deepClone');
  }
  const targetObj: any[] | any = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (
      source[keys] &&
      typeof source[keys] === 'object' &&
      !dDeep.find((item) => item === source[keys])
    ) {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
};

import {
  Col,
  Row,
  Form,
  FormItem,
  Radio,
  RadioGroup,
  Input,
  Option,
  Select,
  Checkbox,
  DatePicker,
  Button,
  Dialog,
  Message,
  MessageBox,
  Menu,
  MenuItem,
  Submenu,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  Pagination,
  Table,
  TableColumn,
  Drawer,
  Loading,
  Upload,
  Tabs,
  TabPane,
  Switch,
  Autocomplete,
  Collapse,
  CollapseItem
} from 'element-ui';

import ScrollBar from 'element-ui/packages/scrollbar'; // 按需加载element-ui隐藏组件scrollbar
import { GlobalVar } from '@/utils/global-var';

export default {
  install(vue: any) {
    vue.use(Row);
    vue.use(Col);
    vue.use(Form);
    vue.use(FormItem);
    vue.use(Radio);
    vue.use(RadioGroup);
    vue.use(Input);
    vue.use(Option);
    vue.use(Select);
    vue.use(Checkbox);
    vue.use(DatePicker);
    vue.use(Button);
    vue.use(Dialog);
    vue.use(Menu);
    vue.use(MenuItem);
    vue.use(Submenu);
    vue.use(Dropdown);
    vue.use(DropdownMenu);
    vue.use(DropdownItem);
    vue.use(Tooltip);
    vue.use(Pagination);
    vue.use(Table);
    vue.use(TableColumn);
    vue.use(ScrollBar);
    vue.use(Drawer);
    vue.use(Upload);
    vue.use(Tabs);
    vue.use(TabPane);
    vue.use(Switch);
    vue.use(Autocomplete);
    vue.use(Collapse);
    vue.use(CollapseItem);
    vue.use(Loading.directive);

    vue.prototype.$message = Message;
    vue.prototype.$confirm = MessageBox.confirm;
    vue.prototype.$alert = MessageBox.alert;
    vue.prototype.$prompt = MessageBox.prompt;
    vue.prototype.$loading = Loading.service;
    // 绑定全局尺寸变量
    vue.prototype.GlobalVar = GlobalVar;
  },
};

import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { getSize,  setLanguage, setSize } from '@/utils/storage_local';
import { getLocale } from '@/lang';
import store from '@/store';

export interface IAppState {
  language: string;
  size: string;
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {

  public language = getLocale();
  public size = getSize() || 'medium';

  @Mutation
  public SET_LANGUAGE(language: string) {
    this.language = language;
    setLanguage(this.language);
  }

  @Mutation
  public SET_SIZE(size: string) {
    this.size = size;
    setSize(this.size);
  }


  @Action
  public SetLanguage(language: string) {
    this.SET_LANGUAGE(language);
  }

  @Action
  public SetSize(size: string) {
    this.SET_SIZE(size);
  }

}

export const AppModule = getModule(App);


import route from './route';
import message from './message';
import layout from './layout';

export default {
  index: {
    principalHeader: 'Principal\'s Message',
    teacherHeader: 'Teacher Spotlights',
    courseHeader: 'Courses',
    sampleLessonHeader: 'Sample Lesson',
    contactUsHeader: 'Contact Us',

    popularCourse: 'Our Most Popular Courses',
    viewAllCourse: 'View All Courses',
    applyNow: 'Apply Now',
    learnMore: 'Learn More',
    course: 'Courses',
    courseAll: 'All Courses',
    sampleLesson: 'Sample Lesson',
    prerequisite: 'Prerequisite',
    courseCode: 'Course Code',
    courseType: 'Course Type',
    courseOutline: 'Course Outline',
    courseDesc: 'Course Description',
    courseSummaryUnitTimelines: 'Summary Of Units and Timelines',
    courseResourceReqByStudent: 'Resources required by the student',
    courseCurriculum: 'Overall Curriculum Expectations',
    applicationProcedure: 'Application Procedure',
    applicationFormsPolicy: 'Application Forms and Policy',
    applicationFAQ: 'FAQs',
    applicationFAQFull: 'Frequently Asked Questions',
    studentSupportCalendar: 'School Course Calendar',
    ossdReq: 'OSSD Requirements',
    resources: 'Resources',
    emailTo: 'Email',
    address: 'Address',
    telephone: 'Tel',
    name: 'Name',
    email: 'Email',
    message: 'Message',
    contactDesc: 'If you have any questions please feel free to contact us.',
    companyAddress: '4002 Sheppard Ave E, unit 418, Scarborough ON M1S 4R5',
    companyTel: '416-684-2321',
    submit: 'Submit',
    copyright: 'Copyright ©2024 GEC World Academy (GEC)',
    website: 'Website',

    prevText: 'Page up',
    nextText: 'Page down',
    contactMissName: 'Please input the name',
    contactMissEmail: 'Please input the email',
    contactMissQuestion: 'Please input the question.',
    submittedQuestReply: 'The question has been sent, we will reply to you as soon as possible!',
    errorMsg: 'Error message',
    confirm: 'Confirm',
  },
  route,
  message,
  ...layout,
};

const languageKey = 'language-gec';
export const getLanguage = () => localStorage.getItem(languageKey);
export const setLanguage = (language: string) => localStorage.setItem(languageKey, language);

// App
const sidebarStatusKey = 'sidebar_status-gec';
export const getSidebarStatus = () => localStorage.getItem(sidebarStatusKey);
export const setSidebarStatus = (sidebarStatus: string) => localStorage.setItem(sidebarStatusKey, sidebarStatus);

const sizeKey = 'size-gec';
export const getSize = () => localStorage.getItem(sizeKey);
export const setSize = (size: string) => localStorage.setItem(sizeKey, size);

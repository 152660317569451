import Vue from 'vue';
import VueI18n from 'vue-i18n';

import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import twLocale from 'element-ui/lib/locale/lang/zh-TW';
import ElementLocale from 'element-ui/lib/locale';

import enLang from './en/index';
import zhLang from './zh/index';
import twLang from './tw/index';

import { getLanguage, setLanguage } from '@/utils/storage_local';

Vue.use(VueI18n);

const messages = {
  'en-us': {
    ...enLang,
    ...enLocale,
  },
  'zh': {
    ...zhLang,
    ...zhLocale,
  },
  'zh-hk': {
    ...twLang,
    ...twLocale,
  },
};

export const getLocale = () => {
  const language = getLanguage();
  if (language) {
    return language;
  }
  // const navLanguage = navigator.language.toLowerCase();
  // const locales = Object.keys(messages);
  // for (const locale in locales) {
  //   if (navLanguage.indexOf(locale) !== -1) {
  //     setLanguage(locale);
  //     return locale;
  //   }
  // }
  return 'en-us'; // 默认简体中文
};

const i18n = new VueI18n({
  locale: getLocale(), // set locale
  messages, // set locale messages
});

ElementLocale.i18n((key: string, value: string) => i18n.t(key, value));

export default i18n;


import { UserModule } from '@/store/modules/user';
// User
const refreshTokenKey = 'gec-refresh-token';
const loginTimeKey = 'LoginTime';
const expiresInKey = 'expiresIn';

export const setRefreshToken = (token: any) => sessionStorage.setItem(refreshTokenKey, token);
export const getRefreshToken = () => sessionStorage.getItem(refreshTokenKey) || '';
export const removeRefreshToken = () => sessionStorage.removeItem(refreshTokenKey);

export const setExpiresIn = (expiresIn: string) => sessionStorage.setItem(expiresInKey, expiresIn);
export const getExpiresIn = () => sessionStorage.getItem(expiresInKey) || '';
export const removeExpiresIn = () => sessionStorage.removeItem(expiresInKey);

export const setLoginTime = (expiresIn: string) => sessionStorage.setItem(loginTimeKey, expiresIn);
export const getLoginTime = () => sessionStorage.getItem(loginTimeKey) || '';
export const removeLoginTime = () => sessionStorage.removeItem(loginTimeKey);


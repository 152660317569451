

export default {
  toolState: {
    completed: [], // 已完成
    UnCompleted: [], // 未启动 与 未完成
    uploadFiles: [],
  },
  toolMutations: {
    addCompleted(state: any, payload: any) {
      if (payload) {
        const index = state.completed.findIndex((item: any) => item === payload);
        if (index < 0) { state.completed.push(payload) };
      }
    },
    subCompleted(state: any, payload: any) {
      if (payload) {
        const index = state.completed.findIndex((item: any) => item === payload);
        if (index > -1) { state.completed.splice(index, 1) };
      }
    },
    addUncompleted(state: any, payload: any) {
      if (payload) {
        const index = state.UnCompleted.findIndex((item: any) => item === payload);
        if (index < 0) { state.UnCompleted.push(payload) };
      }
    },
    subUncompleted(state: any, payload: any) {
      if (payload) {
        const index = state.UnCompleted.findIndex((item: any) => item === payload);
        if (index > -1) { state.UnCompleted.splice(index, 1) };
      }
    },
    addUploadedFile(state: any, payload: any) {
      if (payload.type === 'init') {
        state.uploadFiles = payload.files
      } else {
        state.uploadFiles.push(...payload.files)
      }
    },
    deleteUploadedFile(state: any, payload: any) {
      const index = state.uploadFiles.findIndex((item: any) => item.fileGuid === payload)
      if (index > -1) { state.uploadFiles.splice(index, 1) };
    },
    clearState(state: any) {
      state.completed = []
      state.UnCompleted = []
      state.uploadFiles = []
    }
  },
}











































import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
@Component({
  name: 'Login',
})
export default class extends Vue {
  get language() {
    return AppModule.language;
  }

  get languageTitle() {
    switch(this.language) {
      case 'zh':
        return '简体';
      case 'zh-hk':
        return '繁體';
      case 'en-us':
        return 'English';
      default:
        return '简体';
    }
  }

  private async handleSetLanguage(lang: string) {
    this.$i18n.locale = lang;
    AppModule.SetLanguage(lang);
    document.documentElement.lang = lang;
    this.$message({
      message: this.$t('message.changeLanguageTips').toString(),
      type: 'success',
    });
  }
}

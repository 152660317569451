import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue';
import { deepClone } from '@/utils/function_utils';
import path from 'path';
Vue.use(VueRouter);

/**
 * ConstantRoutes
 * 没有权限需求的基本页面路由，所有角色都可以访问
 */
export const constantRoutes: RouteConfig[] = [
  {
    path: '/404',
    name: 'error',
    meta: { hidden: true },
    component: () => import(/* webpackChunkName: "error" */ '@/views/error-page/error.vue'),
  },
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/index.vue'),
      },
    ],
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    meta: {
      icon: 'home',
      title: 'index',
      hidden: true,
      canClick: true, // 有嵌套路由时，是否可以为路由的一级侧栏
    },
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/Index.vue'),
        meta: {
          hidden: true,
          activeMenu: '/',
        },
      },
    ]
  },
  {
    path: '/about',
    component: Layout,
    redirect: '/about/Principal',
    meta: {
      title: 'ABOUT',
      titleCN: '关于',
      titleZH: '關於',
      hidden: false,
      canClick: true, // 有嵌套路由时，是否可以为路由的一级侧栏
    },
    children: [
      {
        path: '/about/Principal',
        name: 'principal',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/About.vue'),
        meta: {
          title: 'Principal\'s Message',
          titleCN: '校长的话',
          titleZH: '校長的話',
          hidden: false
        },
      },
      {
        path: '/about/Teacher',
        name: 'teacher',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/About.vue'),
        meta: {
          title: 'Teacher Spotlights',
          titleCN: '教师聚焦',
          titleZH: '教師聚焦',
          hidden: false
        },
      },
    ]
  },
  {
    path: '/academic',
    component: Layout,
    redirect: '/courseList',
    meta: {
      title: 'ACADEMIC',
      titleCN: '学术',
      titleZH: '學術',
      hidden: false,
      canClick: true, // 有嵌套路由时，是否可以为路由的一级侧栏
    },
    children: [
      {
        path: '/courseList',
        name: 'courseList',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/CourseList.vue'),
        meta: {
          title: 'Courses of Study',
          titleCN: '学习课程',
          titleZH: '學習課程',
          hidden: false
        },
      },
      {
        path: '/course/:id',
        name: 'course',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/Course.vue'),
        meta: {
          title: 'Courses',
          titleCN: '课程',
          titleZH: '課程',
          hidden: true
        },
      },
      {
        path: '/sampleLesson',
        name: 'sampleLesson',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/SampleLesson.vue'),
        meta: {
          title: 'Sample Lesson',
          titleCN: '范例课程',
          titleZH: '範例課程',
          hidden: false
        },
      },
      {
        path: '/sampleLesson/:id',
        name: 'sampleLessonFiltered',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/SampleLesson.vue'),
        meta: {
          title: 'Sample Lesson Filtered',
          titleCN: '范例课程筛选',
          titleZH: '範例課程篩選',
          hidden: true
        },
      },
    ]
  },
  {
    path: '/applyNow',
    component: Layout,
    redirect: '/applicationProcedure',
    meta: {
      title: 'APPLY NOW',
      titleCN: '立即申请',
      titleZH: '立即申請',
      hidden: false,
      canClick: true, // 有嵌套路由时，是否可以为路由的一级侧栏
    },
    children: [
      {
        path: '/applicationProcedure',
        name: 'applicationProcedure',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/ApplicationProcedure.vue'),
        meta: {
          title: 'Application Procedure',
          titleCN: '申请程序',
          titleZH: '申请程序',
          hidden: false
        },
      },
      {
        path: '/applicationFormsPolicy',
        name: 'applicationFormsPolicy',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/ApplicationProcedure.vue'),
        meta: {
          title: 'Application Forms and Policy',
          titleCN: '申请表和政策',
          titleZH: '申請表和政策',
          hidden: false
        },
      },
      {
        path: '/applicationFaq',
        name: 'applicationFaq',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/ApplicationProcedure.vue'),
        meta: {
          title: 'FAQs',
          titleCN: '常见问题',
          titleZH: '常見問題',
          hidden: false
        },
      },
    ]
  },
  {
    path: '/contact',
    component: Layout,
    redirect: '/contactUs',
    meta: {
      title: 'CONTACT',
      titleCN: '联络我们',
      titleZH: '聯絡我們',
      hidden: false,
      canClick: true, // 有嵌套路由时，是否可以为路由的一级侧栏
    },
    children: [
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import(/* webpackChunkName: "index" */ '../views/index/Contact.vue'),
        meta: {
          title: 'Contact Us',
          titleCN: '联络我们',
          titleZH: '聯絡我們',
          hidden: true
        },
      },
    ]
  },
];

// 异步路由
export const asyncRoutes: RouteConfig[] = [
  { path: '*', redirect: '/404' },
];
const createRouter = () => new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      // 当且仅当 popstate 导航 (通过浏览器的 前进/后退 按钮触发) 时才可用
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRoutes,
});

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher;
}

// 二级以上的菜单降级成二级菜单
const formatRouter = (routes: any, basePath = '/', list: any[] = [], parent: any) => {
  routes.map((item: any) => {
    item.path = path.resolve(basePath, item.path)
    const meta = item.meta || {};
    if (!meta.parent && parent) {
      meta.parent = parent.path;
      item.meta = meta;
    }
    if (item.redirect && item.redirect !== 'noRedirect') {
      item.redirect = path.resolve(basePath, item.redirect)
    }
    if (item.children && item.children.length > 0) {
      const arr = formatRouter(item.children, item.path, list, item);
      delete item.children;
      list.concat(arr);
    }
    list.push(item);
  })
  return list
}

// 菜单降级
export const getFlatRoutes = (routes: RouteConfig[]) => {
  return routes.map((child: RouteConfig) => {
    if (child.children && child.children.length > 0) {
      child.children = formatRouter(child.children, child.path, [], child);
    }
    return child;
  })
}

// 降级后的路由
export const flatRoutes = getFlatRoutes(deepClone(asyncRoutes, ['component']));

export default router;

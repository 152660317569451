





























import { Component, Vue, Ref } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import FooterNavItem from './FooterNavItem.vue';
import { isMobile } from '@/utils/validate';
@Component({
  name: 'FooterNav',
  components: {
    FooterNavItem
  },
})
export default class extends Vue {
  get isMobile() {
    return isMobile();
  }
  get langEN() {
    if (AppModule.language === 'en-us') {
        return true;
    } else {
        return false;
    }
  }
  get routes() {
    return (this.$router as any).options.routes;
  }
}

import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  setExpiresIn,
  getExpiresIn,
  removeExpiresIn,
  setLoginTime,
  getLoginTime,
  removeLoginTime,
} from '@/utils/storage_session';
import { setToken, removeToken, getToken } from '@/utils/cookie_auth';
import { PermissionModule } from '@/store/modules/permission'
import router, { resetRouter } from '@/router';
// 声明user模块的state变量类型
export interface IUserState {
  token: string|any;
  expiresIn: string;
  loginTime: string;
  name: string;
  nameEn: string;
  nickname: string;
  showIndexTabs: boolean;
}

/**
 * 通过命名空间的形式来使用module，这里的namespaced值即为user
 * store 为主入口页对应的整个vuex模块的store
 */
@Module({ dynamic: true, store, name: 'user'})
class User extends VuexModule implements IUserState {
  public token = getToken();
  public expiresIn = getExpiresIn();
  public loginTime = getLoginTime();
  public loginId = '';
  public name = '';
  public nameEn = '';
  public nickname = '';
  public isShowAppStatusPermission = false;
  public personInfo = {}
  public showIndexTabs = true;

  get getToken() {
    return this.token;
  }

  // 一旦使用@Mutation装饰某一函数后, 函数内的this上下文即指向当前的state
  @Mutation
  public SET_TOKEN_INFO(tokenInfo: any) {
    this.token = tokenInfo.access_token;
    this.expiresIn = tokenInfo.expires_in;
    this.loginTime = tokenInfo.login_time;
    setToken(this.token);
    setExpiresIn(this.expiresIn);
    setLoginTime(this.loginTime);
  }


  @Mutation
  public SET_NAME(name: string) {
    this.name = name;
  }
  @Mutation
  public SET_LOGINID(loginId: string) {
    this.loginId = loginId;
  }
  @Mutation
  public SET_NAMEEN(nameEn: string) {
    this.nameEn = nameEn;
  }

  @Mutation
  public SET_NICKNAME(nickname: string) {
    this.nickname = nickname;
  }


  @Mutation
  public SET_USER_ROOT(userRoot: boolean) {
    this.isShowAppStatusPermission = userRoot;
  }



  @Mutation
  public SET_INDEX_SHOW_TABS(showIndexTabs: boolean) {
    this.showIndexTabs = showIndexTabs;
  }

  @Action
  public setIndexTabsShow(showIndexTabs: boolean) {
    this.SET_INDEX_SHOW_TABS(showIndexTabs);
  }

  @Action
  public RefreshUserInfo(user: any) {
    this.SET_NAME(user.name);
    this.SET_LOGINID(user.loginId);
    this.SET_NAMEEN(user.nameEn);
    this.SET_NICKNAME(user.nickname);
  }

  @Action
  public ResetToken() {
    removeToken();
    removeExpiresIn();
    removeLoginTime();
    this.SET_TOKEN_INFO({
      expires_in: '',
      login_time: '',
      access_token: '',
    });
  }

  @Action
  public async LogOut() {
    try {
      this.SET_NAME('');
      this.SET_NAMEEN('');
      this.SET_NAMEEN('');
      this.SET_NICKNAME('');
      this.ResetToken();
      resetRouter();
    } catch (err) {
      return Promise.reject();
    }
  }
}

export const UserModule = getModule(User);

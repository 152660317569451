
import route from './route';
import message from './message';
import layout from './layout';

export default {
  index: {
    principalHeader: '校長的話',
    teacherHeader:'教師聚焦',
    courseHeader: '課程',
    sampleLessonHeader: '範例課程',
    contactUsHeader: '聯絡我們',

    popularCourse: '我們最受歡迎的課程',
    viewAllCourse: '查看所有課程',
    applyNow: '立即申請',
    learnMore: '了解更多',
    course: '課程',
    courseAll: '所有範例課程',
    sampleLesson: '範例課程',
    prerequisite: '條件',
    courseCode: '課程號碼',
    courseType: '課程類型',
    courseOutline: '課程大綱',
    courseDesc: '課程描述',
    courseSummaryUnitTimelines: '單位與時間軸摘要',
    courseResourceReqByStudent: '學生所需資源',
    courseCurriculum: '整體課程期望',
    applicationProcedure: '申請程序',
    applicationFormsPolicy: '申請表和政策',
    applicationFAQ: '常見問題',
    applicationFAQFull: '常見問題',
    studentSupportCalendar: '學校課程日曆',
    ossdReq: 'OSSD 要求',
    resources: '資源',
    emailTo: '電郵',
    address: '地址',
    telephone: '電話',
    name: '名稱',
    email: '電郵',
    message: '訊息',
    contactDesc: '如果您有任何疑問，請隨時與我們聯繫。',
    companyAddress: '4002 Sheppard Ave E, unit 418, Scarborough ON M1S 4R5',
    companyTel: '416-684-2321',
    submit: '提交',
    copyright: '版權 ©2024 GEC環球教育學院 (GEC)',
    website: '網站',

    prevText: '上一頁',
    nextText: '下一頁',
    contactMissName: '請輸入姓名',
    contactMissEmail: '請輸入電郵',
    contactMissQuestion: '請輸入問題',
    submittedQuestReply: '問題已發送，盡快回覆你！',
    errorMsg: '錯誤信息',
    confirm: '確定',
  },
  route,
  message,
  ...layout,
};

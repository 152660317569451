import route from './route';
import message from './message';
import layout from './layout';

export default {
  index: {
    principalHeader: '校长的话',
    teacherHeader:'教师聚焦',
    courseHeader: '课程',
    sampleLessonHeader: '范例课程',
    contactUsHeader: '联络我们',

    popularCourse: '我们最受欢迎的课程',
    viewAllCourse: '查看所有课程',
    applyNow: '立即申请',
    learnMore: '了解更多',
    course: '课程',
    courseAll: '所有范例课程',
    sampleLesson: '范例课程',
    prerequisite: '条件',
    courseCode: '课程号码',
    courseType: '课程类型',
    courseOutline: '课程大纲',
    courseDesc: '课程描述',
    courseSummaryUnitTimelines: '单位与时间轴摘要',
    courseResourceReqByStudent: '学生所需资源',
    courseCurriculum: '整体课程期望',
    applicationProcedure: '申请程序',
    applicationFormsPolicy: '申请表和政策',
    applicationFAQ: '常见问题',
    applicationFAQFull: '常见问题',
    studentSupportCalendar: '学校课程日历',
    ossdReq: 'OSSD 要求',
    resources: '资源',
    emailTo: '电邮',
    address: '地址',
    telephone: '电话',
    name: '名称',
    email: '电邮',
    message: '讯息',
    contactDesc: '如果您有任何疑问，请随时与我们联系。 ',
    companyAddress: '4002 Sheppard Ave E, unit 418, Scarborough ON M1S 4R5',
    companyTel: '416-684-2321',
    submit: '提交',
    copyright: '版权 ©2024 GEC环球教育学院 (GEC)',
    website: '网站',
    prevText: '上一页',
    nextText: '下一页',
    contactMissName: '请输入姓名',
    contactMissEmail: '请输入电邮',
    contactMissQuestion: '请输入问题',
    submittedQuestReply: '问题已发送，尽快回覆你!',
    errorMsg: '错误信息',
    confirm: '确定',
  },
  route,
  message,
  ...layout,
};













import { Component, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component({
  components: {
  },
  name: 'AppMain',
})
export default class extends Vue {

  get key() {
    return this.$route.path;
  }

  get language() {
    return AppModule.language;
  }

  public dialogVisible = false;

  public changeIndexTab(tabItem: any) {
    this.$router.push({ name: tabItem.itemName });
  }

  public handleRegister() {
    this.$router.push({ name: 'studentRegister' });
  }
}

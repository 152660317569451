



















































































import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import LangSelect from '@/components/GecLang/index.vue';
import variables from '@/styles/_variables.scss';
import NavbarItem from './NavbarItem.vue';
import { isMobile } from '@/utils/validate';
@Component({
  name: 'Navbar',
  components: {
    NavbarItem,
    LangSelect,
  },
})
export default class extends Vue {
  @Ref() public linkwrap!: HTMLDivElement;
  public selectMenuIndex = -1;
  public curMenuIndex = -1;
  public navOpen = false;

  get isMobile() {
    return isMobile();
  }
  get langEN() {
      if (AppModule.language === 'en-us') {
          return true;
      } else {
          return false;
      }
  }
  get userName() {
    return `${this.$t('navbar.webcomeBack')} ${UserModule.name || this.$t('navbar.noName')}`;
  }
  public async logout() {
    await UserModule.LogOut().catch((err) => err);
    this.$router.push(`/login?redirect=${this.$route.fullPath}`)
  }
  get variables() {
    return variables;
  }
  get routes() {
    // return (this.$router as any).options.routes.filter((v: any) => v.name === 'home')[0].children;
    return (this.$router as any).options.routes
  }
  get activeMenu() {
    const route = this.$route;
    const { meta, path }: any = route;
    // if set path, the navbar will highlight the path you set
    if (meta.activeMenu) {
      return meta.activeMenu;
    }
    return path;
  }
  public goMain() {
    window.location.href = '../index';
  }
  public toggleSideMenu() {
    this.navOpen = !this.navOpen;
    this.selectMenuIndex = -1;
  }
  public backMainSideMenu() {
    this.selectMenuIndex = -1;
  }
  @Watch('$route', { immediate: true })
  public onRouteChange(route: any) {
    if (isMobile() === true) {
      this.navOpen = false;
      this.selectMenuIndex = -1;
    }
  }
}











import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { AppModule } from '@/store/modules/app';

@Component({
    // Set 'name' here to prevent uglifyjs from causing recursive component not work
    // See https://medium.com/haiiro-io/element-component-name-with-vue-class-component-f3b435656561 for detail
    name: 'FooterNavItem',
    components: {
    },
})
export default class extends Vue {
@Prop({ required: true }) private item!: RouteConfig;
    get langCN() {
        if (AppModule.language === 'zh') {
            return true;
        } else {
            return false;
        }
    }
    get langEN() {
        if (AppModule.language === 'en-us') {
            return true;
        } else {
            return false;
        }
    }
    public goMenu(item: any) {
        window.location.href = '..' + item.path;
    }
    public goSubMenu(item: any) {
        window.location.href = '..' + item.path;
    }
}
